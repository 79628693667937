import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { Text } from "components";

const shapes = {
	round: "rounded-[5px]",
};
const variants = {
	fill: {
		white_A700: "bg-white-A700 text-gray-500",
		gray_100_03: "bg-gray-100_02 text-gray-500",
	},
};
const sizes = {
	xs: "h-[33px] px-4 text-lg",
	lg: "h-[86px] px-[29px]",
	sm: "h-[56px] px-[15px] text-xl",
	md: "h-[65px] pl-[20px] pr-[5px] text-lg",
};

const SelectBox = React.forwardRef(
	(
		{
			children,
			className = "",
			options = [],
			isSearchable = false,
			isMulti = false,
			indicator,
			label = "",
			required = false,
			shape = "round",
			size = "xs",
			variant = "fill",
			color = "white_A700",
			error = "",
			onChange,
			handleChange,
			value,
			...restProps
		},
		ref,
	) => {
		// Helper function to get the selected option
		const getSelectedOption = (value, options) => {
			return options.find(option => option.value === value);
		};
		return (
			<>
				<div className="flex flex-col items-start justify-start w-full gap-[7px]">
					<Text as="p" className="!text-blue_gray-700">
						{ !!label && label }{ required && <span className="text-red-500">*</span> }
					</Text>
					<Select
						ref={ ref }
						options={ options }
						className={ `${ className } flex ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[color]) || "" }` }
						isSearchable={ isSearchable }
						isMulti={ isMulti }
						onChange={ handleChange }
						value={ getSelectedOption(value, options) }
						components={ {
							IndicatorSeparator: () => null,
							...(indicator && { DropdownIndicator: () => indicator }),
						} }
						styles={ {
							container: (provided) => ({
								...provided,
								zIndex: 0,
							}),
							control: (provided) => ({
								...provided,
								backgroundColor: "transparent",
								border: "0 !important",
								boxShadow: "0 !important",
								minHeight: "auto",
								width: "100%",
								"&:hover": {
									border: "0 !important",
								},
							}),
							input: (provided) => ({
								...provided,
								color: "inherit",
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isSelected && "#282c6d",
								color: state.isSelected && "#ffffff",
								"&:hover": {
									backgroundColor: "#282c6d",
									color: "#ffffff",
								},
							}),
							valueContainer: (provided) => ({
								...provided,
								padding: 0,
							}),
							placeholder: (provided) => ({
								...provided,
								margin: 0,
							}),
							menuPortal: (base) => ({ ...base, zIndex: 999999 }),
						} }
						menuPortalTarget={ document.body }
						closeMenuOnScroll={ (event) => {
							return event.target.id === "scrollContainer";
						} }
						{ ...restProps }
					/>
					{ children }
					{ error !== "" && <p className="text-red-500 text-xs italic w-full">{ error }</p> }
				</div>
			</>
		);
	},
);

SelectBox.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	isSearchable: PropTypes.bool,
	isMulti: PropTypes.bool,
	value: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	indicator: PropTypes.node,
	shape: PropTypes.oneOf(["round"]),
	size: PropTypes.oneOf(["sm", "md", "xs"]),
	variant: PropTypes.oneOf(["fill"]),
	color: PropTypes.oneOf(["white_A700", "gray_100_03"]),
};

export { SelectBox };
