import React from "react";
import Header from "../../components/Header";
import { Hero } from "layout/Institutions";
import Footer from "../../components/Footer";
import { ResetPasswordForm } from "layout/Auth/ResetPasswordForm";

export default function Register() {
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			<Hero imageUrl="images/img_image_195.png" title="" subtitle="Sharing is Caring" />
			<ResetPasswordForm />
			<Footer />
		</div>
	);
}
