import React from "react";

const sizes = {
	"2xl": "text-[90px] font-extrabold leading-[108.3%]",
	xl: "text-[60px] font-extrabold leading-[120%]",
	s: "text-base font-extrabold leading-[120%]",
	md: "text-2xl md:text-[30px] font-extrabold leading-[120%]",
	xs: "text-xl font-extrabold",
	lg: "text-3xl font-extrabold md:leading-[120%]",
};

const Heading = ({ children, className = "", size = "s", as, ...restProps }) => {
	const Component = as || "h6";

	return (
		<Component className={ `text-blue_gray-800 font-shipporimincho ${ className } ${ sizes[size] }` } { ...restProps }>
			{ children }
		</Component>
	);
};

export { Heading };
