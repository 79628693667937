import React from "react";
import { Button, Heading, Text, Modal } from "../../components";

export default function ModalUnauthorized({ isOpen, onButtonClick, onCancelClick }) {

	return (
		<Modal
			isOpen={ isOpen }
			size="sm"
			className="flex flex-col py-8 px-8 gap-8">

			<div className="flex flex-col gap-0">
				<Text as="p" className="w-full !text-blue_gray-800 !font-medium">
					{ localStorage['flash'] }
				</Text>
			</div>
			<div className="grid grid-cols-2 gap-2">
				<div onClick={ onCancelClick } className="flex justify-center">
					<Button size="xs" color='gray_100_03' className="uppercase !text-sm w-full">Cancel</Button>
				</div>
				<div onClick={ onButtonClick } className="flex justify-center">
					<Button size="xs" className="uppercase !text-sm w-full">Okay</Button>
				</div>
			</div>
		</Modal>
	);
}