import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'utils/quill-editor/Quill.css';
const Description = ({ charity }) => {
	return (
		<div className="flex flex-col justify-start w-full px-10 gap-10 mt-5 mb-10">
			<ReactQuill
				className="w-full !text-blue_gray-700 px-10 mt-5"
				value={ charity.description } readOnly={ true }
				theme="bubble"
				modules={ { toolbar: false } } />
			{/* <Heading as="p" className="!text-base !font-normal !text-blue_gray-700" dangerouslySetInnerHTML={ { __html: charity.description } } /> */ }
		</div>
	);
};

export default Description;