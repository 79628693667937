import React from "react";
import { Button, Text, Modal } from "../../components";

export default function ModalBank({ institutions, isOpen, amount, onButtonClick, onCancelClick }) {

	return (
		<Modal
			isOpen={ isOpen }
			size="lg"
			className="flex flex-col py-5 px-10 lg:px-10 gap-10 max-w-[350px] w-[80%] md:w-[350px]">
			<div className="flex flex-col justify-center items-center w-full">
				<div className="flex justify-center w-full gap-2">
					<Text className="font-bold !text-black !text-lg mt-3">
						Confirmation
					</Text>
				</div>
				<Text className="!text-black !text-base mt-3">
					Are you sure you want to donate <b>${ amount }</b> to { institutions }?
					<br />
					By confirming, your payment
					will be process on the next step.
				</Text>
			</div>

			<div className="flex justify-between">
				<Button color="gray_100_03" onClick={ onCancelClick } size="xs" className="text-black uppercase text-xs md:!text-sm !px-6">CANCEL</Button>
				<Button onClick={ onButtonClick } size="xs" className="uppercase text-xs md:!text-sm  !px-6">CONTINUE</Button>
			</div>
		</Modal>
	);
}