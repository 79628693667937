import React, { useEffect, useState } from "react";
import { Heading, Input, SelectBox, Text } from "components";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { IconSquare, IconSquareCheck } from "@tabler/icons";

const schema = yup
	.object({
		kyc_fullname: yup.string().required().max(50),
		kyc_reg_no: yup.string().required(),
		kyc_place_incorporation: yup.string().required(),
		kyc_date_of_incorporation: yup.string().required(),
		kyc_license_no: yup.string().required(),
		kyc_date_of_license: yup.string().required(),
		kyc_phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
		kyc_email: yup.string().email().required().max(50),
		kyc_website: yup.string().required(),
		kyc_registered_address: yup.string().required(),
		kyc_city: yup.string().required(),
		kyc_postal_code: yup.string().matches(/^[0-9]+$/, "Must be only digits").required(),
		kyc_registered_country: yup.string().required(),
		kyc_registered_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_contact_fullname: yup.string().required(),
		kyc_position: yup.string().required(),
		kyc_office_phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
		kyc_emergency_email: yup.string().email().required().max(50),
	})
	.required();

const CorporateInfo = ({ setSameAddress, sameAddress }) => {
	const { register, formState: { errors }, setValue, getValues } = useFormContext();

	return (
		<div className="mt-[3px] w-full flex flex-col gap-5">
			<Heading as="p" size="xs" className=" w-full">
				Corporate Info
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_fullname" label="Corporate Name" placeholder="Corporate Name*" className="w-full" autocomplete="off" { ...register('kyc_fullname') }
					error={ errors?.kyc_fullname?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_reg_no" label="Registration Number" placeholder="Registration Number*" className="w-full" autocomplete="off" { ...register('kyc_reg_no') }
					error={ errors?.kyc_reg_no?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_place_incorporation" label="Place of Incorporation" placeholder="Place of Incorporation*" className="w-full" autocomplete="off" { ...register('kyc_place_incorporation') }
					error={ errors?.kyc_place_incorporation?.message } />
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label="Date of Incorporation"
					placeholder="Date of Incorporation"
					name="kyc_date_of_incorporation"
					type="date"
					className="w-full"
					{ ...register('kyc_date_of_incorporation') }
					error={ errors?.kyc_date_of_incorporation?.message }
				/>
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_license_no" label="License Number" placeholder="License Number*" className="w-full" autocomplete="off" { ...register('kyc_license_no') }
					error={ errors?.kyc_license_no?.message } />
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label="Date of License"
					placeholder="Date of License"
					name="kyc_date_of_license"
					type="date"
					className="w-full"
					{ ...register('kyc_date_of_license') }
					error={ errors?.kyc_date_of_license?.message }
				/>
				<Input required color="gray_100_02" type="email" variant="fill" name="kyc_email" label="Company Email" placeholder="Company Email*" className="w-full" autocomplete="off" { ...register('kyc_email') }
					error={ errors?.kyc_email?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_phone" label="Company Phone Number" placeholder="Company Phone Number*" className="w-full" autocomplete="off" { ...register('kyc_phone') }
					error={ errors?.kyc_phone?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_website" label="Company Website" placeholder="Company Website*" className="w-full" autocomplete="off" { ...register('kyc_website') }
					error={ errors?.kyc_website?.message } />
			</div>

			<Heading as="p" size="xs" className=" w-full mt-5">
				Corporate Address Info
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_registered_address" label="Registered Address" placeholder="Registered Address*" className="col-span-2 w-full" autocomplete="off" { ...register('kyc_registered_address') }
					error={ errors?.kyc_registered_address?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_city" label="Town/City" placeholder="Town/City*" className="w-full" autocomplete="off" { ...register('kyc_city') }
					error={ errors?.kyc_city?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_postal_code" label="Postal Code" placeholder="Postal Code*" className="w-full" autocomplete="off" { ...register('kyc_postal_code') }
					error={ errors?.kyc_postal_code?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_registered_country" label="Registered Country" placeholder="Registered Country*" className="w-full" autocomplete="off" { ...register('kyc_registered_country') }
					error={ errors?.kyc_registered_country?.message } />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_registered_files" label="Upload Address Proof" placeholder="Upload Address Proof*" className="w-full " autocomplete="off" tooltip="eg. Company Electricity Bill,
Water Bill, Internet Bill" error={ errors?.kyc_registered_files?.message } { ...register('kyc_registered_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<div className="flex flex-col items-start justify-start w-full gap-[7px]">
					<Text as="p" className="!text-blue_gray-700">
						Business Address
					</Text>
					<div className="flex gap-1 items-center h-full ">
						{
							sameAddress ?
								<IconSquareCheck color="#717171" onClick={ () => setSameAddress(false) } /> :
								<IconSquare color="#717171" onClick={ () => setSameAddress(true) } />
						}
						<Text className="!text-gray-600 !text-sm">Same with personal info</Text>
					</div>
				</div>
				{
					!sameAddress && <>
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_business_address" label="Business Address" placeholder="Business Address*" className="col-span-2 w-full" autocomplete="off" { ...register('kyc_business_address') }
							error={ errors?.kyc_business_address?.message } />
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_business_city" label="Town/City" placeholder="Town/City*" className="w-full" autocomplete="off" { ...register('kyc_business_city') }
							error={ errors?.kyc_business_city?.message } />
						<Input required color="gray_100_02" type="number" variant="fill" name="kyc_business_postal_code" label="Postal Code" placeholder="Postal Code*" className="w-full" autocomplete="off" { ...register('kyc_business_postal_code') }
							error={ errors?.kyc_business_postal_code?.message } />
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_business_country" label="Business Country" placeholder="Business Country*" className="w-full" autocomplete="off" { ...register('kyc_business_country') }
							error={ errors?.kyc_business_country?.message } /></>
				}
			</div>

			<Heading as="p" size="xs" className=" w-full mt-5">
				Emergency Contact
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_contact_fullname" label="Full Name as in Proof" placeholder="Full Name as in Proof*" className="w-full" autocomplete="off" { ...register('kyc_contact_fullname') }
					error={ errors?.kyc_contact_fullname?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_position" label="Position" placeholder="Position*" className="w-full" autocomplete="off" { ...register('kyc_position') }
					error={ errors?.kyc_position?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_office_phone" label="Office Tel Number" placeholder="Office Tel Number*" className="w-full" autocomplete="off" { ...register('kyc_office_phone') }
					error={ errors?.kyc_office_phone?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_mobile_phone" label="Mobile Tel Number" placeholder="Mobile Tel Number*" className="w-full" autocomplete="off" { ...register('kyc_mobile_phone') }
					error={ errors?.kyc_mobile_phone?.message } />
				<Input required color="gray_100_02" type="email" variant="fill" name="kyc_emergency_email" label="Email" placeholder="Email*" className="w-full" autocomplete="off" { ...register('kyc_emergency_email') }
					error={ errors?.kyc_emergency_email?.message } />
			</div>
		</div>
	);
};

export { CorporateInfo, schema as CorporateInfoSchema };