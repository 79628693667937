import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { LoaderContainer } from "react-global-loader";
import { Loading } from "components/Loading";

function App() {
  return (
    <Router>
      <Routes />

      <LoaderContainer hideDuration={3000} autoHide>
        <Loading />
      </LoaderContainer>

    </Router>
  );
}

export default App;
