import React, { useState } from "react";
import { Img, Heading, Text, Button, Input, TextArea } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from 'api/Api';
import Cache from 'utils/cache';


const schema = yup
	.object({
		fullname: yup.string().required().max(50),
		username: yup.string().email().required().max(50),
		phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
		address: yup.string().required(),
	})
	.required();


const EditProfileForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			fullname: Cache.getUser()?.fullname,
			username: Cache.getUser()?.username,
			phone: Cache.getUser()?.phone,
			address: Cache.getUser()?.address
		}
	});
	const onSubmit = (data) => {
		loader.showLoader();

		API.put(`${ Cache.getUser()?.id }`, data).then(e => {
			loader.hideLoader();
			setSuccessMessage('Please re-login to update the changes');
			setTimeout(() => {
				setSuccessMessage('');
				Cache.clearLogin();
				document.location = '/login';
			}, 3000);
		}).catch(e => {
			loader.hideLoader();

			setErrorMessage('Change a few things up and try submitting again.');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		});
	};


	return (
		<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
			{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
				<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
				</svg>
				<span className="sr-only">Info</span>
				<div className="ml-2 mr-2">
					<span className="font-medium">Error!</span> { errorMessage }
				</div>
			</div> }
			{ successMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
				<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
				</svg>
				<span className="sr-only">Info</span>
				<div className="ml-2 mr-2">
					<span className="font-medium">Success!</span>  { successMessage }
				</div>
			</div>
			}

			<div className="grid grid-cols-2 gap-10 w-full">
				<Input color="gray_100_02" type="text" variant="fill" name="fullname" label="Fullname as in ID Proof" placeholder="Fullname*" className="w-full" autocomplete="off" { ...register('fullname') }
					error={ errors?.fullname?.message } />
				{/* { type === "personal" && */ }
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label="Date of Birth"
					placeholder="Date of Birth"
					name="date_of_birth"
					type="date"
					className="w-full h-[40px]"
					{ ...register('date_of_birth') }
					error={ errors?.date_of_birth?.message }
				/>
				{/* } */ }
				<Input color="gray_100_02" type="email" variant="fill" name="username" label="Email Address" placeholder="Email Address*" className="w-full" autocomplete="off" { ...register('username') }
					error={ errors?.username?.message } />
				<Input color="gray_100_02" type="text" variant="fill" label="Phone Number" name="phone" placeholder="Phone*" className="w-full" autocomplete="off" { ...register('phone') }
					error={ errors?.phone?.message } />
			</div>
			<div className="mt-5 flex justify-end w-full ">
				<Button size="sm" className="!text-gray-100_02 font-medium min-w-[201px] !rounded-lg" type='submit'>Submit</Button>
			</div>
		</form>
	);
};

export { EditProfileForm };