import React, { useState } from "react";
import { Img, Heading, Text, Button, Input } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from "api/Api";
import Cache from "utils/cache";
import { useSearchParams } from 'react-router-dom';


const schema = yup
	.object({
		new_password: yup.string().required().min(6),
		password_confirmation: yup.string().required().min(6).oneOf([yup.ref('new_password'), null], 'Passwords must match')
	})
	.required();

const ResetPasswordForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			new_password: '',
			password_confirmation: ''
		}
	});
	const [searchParams, setSearchParams] = useSearchParams();

	const onSubmit = (data) => {
		const token = searchParams.get("token");

		if (token === null || token === '') {
			setErrorMessage('Reset Password link is invalid');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
			return;
		}
		loader.showLoader();
		data['token'] = token;

		API.post(`forgotpassword/verify`, data).then(e => {
			loader.hideLoader();
			setSuccessMessage(e.data.send_result);
			setTimeout(() => {
				setSuccessMessage('');
				document.location = '/login';
			}, 3000);
		}).catch(e => {
			loader.hideLoader();

			setErrorMessage('Change a few things up and try submitting again.');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		});
	};


	return (
		<div className="relative">
			<div className=" py-20 grid grid-cols-1 gap-10  max-w-[480px] w-full md:w-[480px] px-5 m min-h-screen-min-topbar">
				<div className="flex flex-col items-start justify-start w-full gap-5">
					<Text size="lg" as="p" className="!text-gray-600 !font-sueellenfrancisco">
						How We Help
					</Text>
					<Heading size="md" as="h2" >
						Join The Community To Give Education For Children
					</Heading>
					<Text size="sm" as="p" className="!text-gray-600 text-justify ">
						Lorem Ipsum is simply dummy text of the printin typesetting dummy text ever when an unknown printer took
						a galley of type and scrambled it to make a type specimen book.{ " " }
					</Text>
					<Img src="/images/img_photo_413x585.png" alt="photo_three" className="w-full object-cover h-[264px]" />
				</div>
				<div className="flex flex-col items-start justify-center w-full gap-8 p-8 border-gray-300 border border-solid bg-white-A700">
					<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
						<Heading size="md" as="h4">
							Reset Password
						</Heading>
						{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
							<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
							</svg>
							<span className="sr-only">Info</span>
							<div className="ml-2 mr-2">
								<span className="font-medium">Error!</span>  { errorMessage }
							</div>
						</div>
						}

						{ successMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
							<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
							</svg>
							<span className="sr-only">Info</span>
							<div className="ml-2 mr-2">
								<span className="font-medium">Success!</span>  { successMessage }
							</div>
						</div>
						}

						<Input color="gray_100_02" type="password" variant="fill" name="new_password" placeholder="Password*" className="w-full" autocomplete="off" { ...register('new_password') }
							error={ errors?.new_password?.message } />
						<Input color="gray_100_02" type="password" variant="fill" name="password_confirmation" placeholder="Password Confirmation*" className="w-full" autocomplete="off" { ...register('password_confirmation') }
							error={ errors?.password_confirmation?.message } />

						<Button className=" !text-gray-100_02 uppercase font-medium min-w-[201px]" type='submit'>Submit</Button>

					</form>
				</div>
			</div>
		</div>
	);
};

export { ResetPasswordForm };