import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { default as ModalProvider } from "react-modal";

const shapes = {
	xl: "rounded-xl",
	md: "rounded-md",
	lg: "rounded-lg",
};

const variants = {
	outline: {
		white_A700: "bg-white-A700 border-gray-600 border border-solid text-blue_gray-800",
	},
	noOutline: {
		white_A700: "bg-white-A700 text-blue_gray-800",
	},
};
const sizes = {
	xl: "w-[90%] lg:w-[60%]",
	lg: "w-[60%]",
	md: "w-[50%]",
};

const Modal = ({
	children,
	className = "",
	isOpen,
	onButtonClick,
	shape = "lg",
	variant = "noOutline",
	size = "md",
	color = "white_A700",
	...restProps
}) => {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);
	return (
		<ModalProvider
			{ ...restProps }
			style={ {
				overlay: {
					backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
				},
				content: {
					outline: "none",
				},
			} }
			appElement={ document.getElementById("root") } isOpen={ isOpen }
			className={ `${ className } ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[color]) || "" }` }
			{ ...restProps }
		>

			{ children }
		</ModalProvider>
	);
};

Modal.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	onButtonClick: PropTypes.func,
	shape: PropTypes.oneOf(["md", "lg", "xl"]),
	size: PropTypes.oneOf(["xs", "lg", "sm", "md", "xl"]),
	variant: PropTypes.oneOf(["outline", "noOutline"]),
	color: PropTypes.oneOf(["white_A700"]),
};

export { Modal };
