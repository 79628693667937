// Payment.js or Payment.jsx

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { http, createConfig, WagmiProvider } from "wagmi";
import { mainnet, arbitrum } from "viem/chains";
import { walletConnect, coinbaseWallet, injected } from "wagmi/connectors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { authConnector } from "@web3modal/wagmi";

const Payment = () => {
	// 0. Setup queryClient
	const queryClient = new QueryClient();

	// 1. Get projectId at https://cloud.walletconnect.com
	// const projectId = import.meta.env.VITE_PROJECT_ID;
	const projectId = 'c4f79cc821944d9680842e34466bfb';
	if (!projectId) throw new Error("Project ID is undefined");

	// 2. Create wagmiConfig
	const metadata = {
		name: "Web3Modal",
		description: "Web3Modal Example",
		url: "https://web3modal.com",
		icons: ["https://avatars.githubusercontent.com/u/37784886"],
	};

	// Define chains
	const chains = [mainnet, arbitrum];

	// create the connectors
	const connectors = [];
	connectors.push(walletConnect({ projectId, metadata, showQrModal: false }));
	connectors.push(injected({ shimDisconnect: true }));
	connectors.push(
		coinbaseWallet({
			appName: metadata.name,
			appLogoUrl: metadata.icons[0],
		})
	);

	connectors.push(
		authConnector({
			options: { projectId },
			socials: ["google", "x", "github", "discord", "apple"],
			showWallets: true,
			email: true,
			walletFeatures: false,
		})
	);

	const wagmiConfig = createConfig({
		chains, // Use the defined chains here
		transports: {
			[mainnet.id]: http(),
			[arbitrum.id]: http(),
		},
		connectors: connectors,
	});

	// 3. Create modal (optional, depending on when you want to create the modal)
	useEffect(() => {
		createWeb3Modal({ wagmiConfig, projectId });
	}, []);

	return (
		<React.StrictMode>
			<WagmiProvider config={ wagmiConfig }>
				<QueryClientProvider client={ queryClient }>
					<div className="flex justify-center items-center h-screen w-full">
						<w3m-button />
					</div>
				</QueryClientProvider>
			</WagmiProvider>
		</React.StrictMode>
	);
};

export default Payment;
