import React from "react";
import { Heading, Input } from "components";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

const schema = yup
	.object({
		kyc_coi_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_license_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_memorandum_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_members_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_directors_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_power_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_authorisation_files: yup
			.mixed()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_additional_files: yup
			.mixed()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
	})
	.required();

const DocumentUpload = () => {
	const { register, formState: { errors }, setValue, getValues } = useFormContext();

	return (
		<div className="mt-[3px] w-full flex flex-col gap-5">
			<Heading as="p" size="xs" className=" w-full">
				Documents (Upload)
			</Heading>
			<div className="grid grid-cols-1 w-full gap-5">
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_coi_files" label="Upload a Certificate of Incorporation (COI) or similar document under which the company or entity was incorporated, established, registered" placeholder="Upload a Certificate of Incorporation (COI)*" className="w-full " autocomplete="off" error={ errors?.kyc_coi_files?.message } { ...register('kyc_coi_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_license_files" label="Upload Certificate of License issued by authorities." placeholder="Upload Certificate of License issued by authorities.*" className="w-full " autocomplete="off" error={ errors?.kyc_license_files?.message } { ...register('kyc_license_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_memorandum_files" label="Upload Memorandum and Articles of Association" placeholder="Upload Memorandum and Articles of Association*" className="w-full " autocomplete="off" error={ errors?.kyc_memorandum_files?.message } { ...register('kyc_memorandum_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_members_files" label="Upload a register of members or equivalent list
confirming the owners of the company or entity" placeholder="Upload a register of members or equivalent list
confirming the owners of the company or entity*" className="w-full " autocomplete="off" error={ errors?.kyc_members_files?.message } { ...register('kyc_members_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_directors_files" label="Upload a register of directors or equivalent list
confirming the management of the company or entity" placeholder="Upload a register of directors or equivalent list
confirming the management of the company or entity*" className="w-full " autocomplete="off" error={ errors?.kyc_directors_files?.message } { ...register('kyc_directors_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_power_files" label="Upload any Power of Attorney or other similar
documents/instruments and confirmation that the
power was given by the Company (eg.,
Company/Director Resolution)" placeholder="Upload any Power of Attorney*" className="w-full " autocomplete="off" error={ errors?.kyc_power_files?.message } { ...register('kyc_power_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input color="gray_100_02" type="file" variant="fill" name="kyc_authorisation_files" label="Upload Corporate Representative Authorisation (If
Applicable)" placeholder="Upload Corporate Representative Authorisation" className="w-full " autocomplete="off" error={ errors?.kyc_authorisation_files?.message } { ...register('kyc_authorisation_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<Input color="gray_100_02" type="file" variant="fill" name="kyc_additional_files" label="Upload Additional Document (If Applicable)" placeholder="Upload Additional Document" className="w-full " autocomplete="off" error={ errors?.kyc_additional_files?.message } { ...register('kyc_additional_files') } accept='.png, .jpg, .jpeg, .pdf' />
			</div>
		</div>
	);
};

export { DocumentUpload, schema as DocumentUploadSchema };