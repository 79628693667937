import React, { useEffect, useState } from "react";
import { Heading, Input, SelectBox, Text } from "components";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { IconSquare, IconSquareCheck } from "@tabler/icons";

const schema = yup
	.object({
		kyc_fullname: yup.string().required().max(50),
		kyc_phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
		kyc_email: yup.string().email().required().max(50),
		kyc_date_of_birth: yup.string().required(),
		kyc_gender: yup.string().required(),
		kyc_marital_status: yup.string().required(),
		kyc_nationality: yup.string().required(),
		kyc_passport_id: yup.string().required(),
		kyc_passport_period: yup.string().required(),
		kyc_passport_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),

		kyc_residential_address: yup.string().required(),
		kyc_city: yup.string().required(),
		kyc_postal_code: yup.string().matches(/^[0-9]+$/, "Must be only digits").required(),
		kyc_residential_country: yup.string().required(),
		kyc_residential_files: yup
			.mixed()
			.required()
			.test("fileSize", "File size exceeds 5 MB", value => {
				return value && value[0] && value[0].size <= 5 * 1024 * 1024;
			})
			.test("fileType", "Unsupported file format", value => {
				return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
			}),
		kyc_contact_relation: yup.string().required(),
		kyc_contact_fullname: yup.string().required(),
		kyc_contact_phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
	})
	.required();

const gender = [
	{ value: "male", label: "Male" },
	{ value: "female", label: "Female" },
];
const marital_status = [
	{ value: "single", label: "Single" },
	{ value: "married", label: "Married" },
];

const PersonalInfo = ({ setSameAddress, sameAddress }) => {
	const { register, formState: { errors }, setValue, getValues } = useFormContext();

	return (
		<div className="mt-[3px] w-full flex flex-col gap-5">
			<Heading as="p" size="xs" className=" w-full">
				Personal Information
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_fullname" label="Full Name as in Proof" placeholder="Fullname*" className="w-full" autocomplete="off" { ...register('kyc_fullname') }
					error={ errors?.kyc_fullname?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_phone" label="Phone Number" placeholder="Phone Number*" className="w-full" autocomplete="off" { ...register('kyc_phone') }
					error={ errors?.kyc_phone?.message } />
				<Input required color="gray_100_02" type="email" variant="fill" name="kyc_email" label="Email Address" placeholder="Email Address*" className="w-full" autocomplete="off" { ...register('kyc_email') }
					error={ errors?.kyc_email?.message } />
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label="Date of Birth"
					placeholder="Date of Birth"
					name="kyc_date_of_birth"
					type="date"
					className="w-full"
					{ ...register('kyc_date_of_birth') }
					error={ errors?.kyc_date_of_birth?.message }
				/>
				<SelectBox
					name="kyc_gender"
					size="md"
					label="Select Gender"
					placeholder="Select Gender"
					color="gray_100_03"
					options={ gender }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_gender', e.value);
					} }
					value={ getValues('kyc_gender') }
					error={ errors?.kyc_gender?.message }
					{ ...register('kyc_gender') }
				/>
				<SelectBox
					name="kyc_marital_status"
					size="md"
					label="Marital Status"
					placeholder="Select Marital Status"
					color="gray_100_03"
					options={ marital_status }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_marital_status', e.value);
					} }
					value={ getValues('kyc_marital_status') }
					error={ errors?.kyc_marital_status?.message }
					{ ...register('kyc_marital_status') }
				/>
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_nationality" label="Nationality" placeholder="Nationality" className="w-full" autocomplete="off" { ...register('kyc_nationality') }
					error={ errors?.kyc_nationality?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_passport_id" label="Passport ID" placeholder="Passport ID*" className="w-full" autocomplete="off" { ...register('kyc_passport_id') }
					error={ errors?.kyc_passport_id?.message } />
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label="Passport Validity Period"
					placeholder="Passport Validity Period"
					name="kyc_passport_period"
					type="date"
					className="w-full"
					{ ...register('kyc_passport_period') }
					error={ errors?.kyc_passport_period?.message }
				/>
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_passport_files" label="Passport Photo" placeholder="Passport Photo*" className="w-full " autocomplete="off"
					error={ errors?.kyc_passport_files?.message } { ...register('kyc_passport_files') } accept='.png, .jpg, .jpeg, .pdf' />
			</div>

			<Heading as="p" size="xs" className=" w-full mt-5">
				Personal Address
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" tooltip="Information symbols: Residential Address is the address for where you currently live" name="kyc_residential_address" label="Residential Address" placeholder="Residential Address*" className="col-span-2 w-full" autocomplete="off" { ...register('kyc_residential_address') }
					error={ errors?.kyc_residential_address?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_city" label="Town/City" placeholder="Town/City*" className="w-full" autocomplete="off" { ...register('kyc_city') }
					error={ errors?.kyc_city?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_postal_code" label="Postal Code" placeholder="Postal Code*" className="w-full" autocomplete="off" { ...register('kyc_postal_code') }
					error={ errors?.kyc_postal_code?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_residential_country" label="Residential Country" placeholder="Residential Country*" className="w-full" autocomplete="off" { ...register('kyc_residential_country') }
					error={ errors?.kyc_residential_country?.message } />
				<Input required color="gray_100_02" type="file" variant="fill" name="kyc_residential_files" label="Upload Residential Address Proof" placeholder="Upload Residential Address Proof*" className="w-full " autocomplete="off" tooltip={ `
    <ul style="list-style-type: disc; padding-left: 10px;">
      <li>Residential Address is the address for where you currently live.</li>
      <li>Color Clear Copy Utilities Bills: Water bill/Electricity Bill/Bank Statement) not more than 03 months.</li>
      <li>Identity card, Driving License, or other ID issued by a government agency is NOT acceptable for address proof.</li>
    </ul>
  `} error={ errors?.kyc_residential_files?.message } { ...register('kyc_residential_files') } accept='.png, .jpg, .jpeg, .pdf' />
				<div className="flex flex-col items-start justify-start w-full gap-[7px]">
					<Text as="p" className="!text-blue_gray-700">
						Corresponding Address
					</Text>
					<div className="flex gap-1 items-center h-full ">
						{
							sameAddress ?
								<IconSquareCheck color="#717171" onClick={ () => setSameAddress(false) } /> :
								<IconSquare color="#717171" onClick={ () => setSameAddress(true) } />
						}
						<Text className="!text-gray-600 !text-sm">Same with personal info</Text>
					</div>
				</div>
				{
					!sameAddress && <>
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_corresponding_address" label="Corresponding Address" placeholder="Corresponding Address*" className="col-span-2 w-full" autocomplete="off" { ...register('kyc_corresponding_address') }
							error={ errors?.kyc_corresponding_address?.message } />
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_corresponding_city" label="Town/City" placeholder="Town/City*" className="w-full" autocomplete="off" { ...register('kyc_corresponding_city') }
							error={ errors?.kyc_corresponding_city?.message } />
						<Input required color="gray_100_02" type="number" variant="fill" name="kyc_corresponding_postal_code" label="Postal Code" placeholder="Postal Code*" className="w-full" autocomplete="off" { ...register('kyc_corresponding_postal_code') }
							error={ errors?.kyc_corresponding_postal_code?.message } />
						<Input required color="gray_100_02" type="text" variant="fill" name="kyc_corresponding_country" label="Corresponding Country" placeholder="Corresponding Country*" className="w-full" autocomplete="off" { ...register('kyc_corresponding_country') }
							error={ errors?.kyc_corresponding_country?.message } /></>
				}
			</div>

			<Heading as="p" size="xs" className=" w-full mt-5">
				Emergency Contact
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_contact_relation" label="Emergency Contact Relation" placeholder="Emergency Contact Relation*" className="w-full" autocomplete="off" { ...register('kyc_contact_relation') }
					error={ errors?.kyc_contact_relation?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_contact_fullname" label="Emergency Contact Full Name" placeholder="Emergency Contact Full Name*" className="w-full" autocomplete="off" { ...register('kyc_contact_fullname') }
					error={ errors?.kyc_contact_fullname?.message } />
				<Input required color="gray_100_02" type="number" variant="fill" name="kyc_contact_phone" label="Emergency Contact Phone Number" placeholder="Emergency Contact Phone Number*" className="w-full" autocomplete="off" { ...register('kyc_contact_phone') }
					error={ errors?.kyc_contact_phone?.message } />
			</div>
		</div>
	);
};

export { PersonalInfo, schema as PersonalInfoSchema };