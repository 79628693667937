import React from "react";
import PropTypes from "prop-types";
import { Text } from "components";
import { Tooltip } from "react-tooltip";
import { IconInfoCircle } from "@tabler/icons";

const shapes = {
	square: "rounded-[0px]",
};
const variants = {
	outline: {
		white_A700: "border-gray-500 border border-solid text-gray-500 rounded-lg",
	},
	fill: {
		gray_100_02: "bg-gray-100_02 text-gray-500",
		white_A700: "bg-white-A700 text-gray-500",
	},
};
const sizes = {
	xs: "h-[51px] pl-[22px] pr-[35px] text-base md:text-lg",
	md: "h-[51px] px-2 text-base md:text-lg",
};

const Input = React.forwardRef(
	(
		{
			className = "",
			name = "",
			placeholder = "",
			type = "text",
			children,
			label = "",
			prefix,
			suffix,
			error = "",
			onChange,
			required = false,
			autocomplete = "",
			shape = "square",
			variant = "outline",
			size = "xs",
			color = "white_A700",
			tooltip = "",
			...restProps
		},
		ref,
	) => {
		const handleChange = (e) => {
			if (onChange && e !== undefined) onChange(e);
		};

		return (

			<>
				<div className="flex flex-col items-start justify-start w-full gap-[7px]">
					<div className="flex justify-between items-center w-full">
						<Text as="p" className="!text-blue_gray-700">
							{ !!label && label }{ required && <span className="text-red-500">*</span> }
						</Text>
						{ tooltip &&
							<>
								<IconInfoCircle color="#717171" width={ 16 } data-tooltip-id={ name } />
								<Tooltip id={ name } place="bottom"> <div
									dangerouslySetInnerHTML={ { __html: tooltip } }
								/></Tooltip>
							</> }
					</div>
					<div
						className={ `${ className } flex items-center justify-center text-lg ${ shapes[shape] || "" } ${ variants[variant]?.[color] || variants[variant] || "" } ${ sizes[size] || "" }` }
					>
						{ !!prefix && prefix }
						<input ref={ ref } type={ type } name={ name } onChange={ handleChange } placeholder={ placeholder } autoComplete={ autocomplete } { ...restProps } />
						{ !!suffix && suffix }
					</div>
					{ error !== "" && <p className="text-red-500 text-xs italic w-full">{ error }</p> }
				</div>
			</>
		);
	},
);

Input.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	prefix: PropTypes.node,
	suffix: PropTypes.node,
	error: PropTypes.string,
	required: PropTypes.bool,
	autocomplete: PropTypes.string,
	shape: PropTypes.oneOf(["square"]),
	size: PropTypes.oneOf(["xs"]),
	variant: PropTypes.oneOf(["outline", "fill"]),
	color: PropTypes.oneOf(["white_A700", "gray_100_02"]),
	tooltip: PropTypes.string,
};

export { Input };
