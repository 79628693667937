import React, { useState } from "react";
import { Img, Heading, Text, Button, Input } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from 'api/Api';
import Cache from 'utils/cache';


const schema = yup
	.object({
		username: yup.string().email().required().max(50),
		password: yup.string().required().min(6),
	})
	.required();


const LoginForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: 'testingcorporate1@yopmail.com',
			password: 'Masuk123',
		}
	});
	const onSubmit = (data) => {
		loader.showLoader();

		// setTimeout(() => {
		//   loader.hideLoader();
		// }, 3000);

		// this.setState({ loading: true });

		API.post(`login`, data).then(e => {
			loader.hideLoader();
			Cache.setUser(e.data);
			document.location = '/';
		}).catch(e => {
			loader.hideLoader();

			setErrorMessage('Change a few things up and try submitting again.');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		});
	};


	return (
		<div className=" grid grid-cols-1 max-w-[480px] mt-10 w-full md:w-[480px] min-h-screen-min-topbar">
			{/* <div className="flex flex-col items-start justify-start w-full gap-5">
					<Text size="lg" as="p" className="!text-gray-600 !font-sueellenfrancisco">
						How We Help
					</Text>
					<Heading size="lg" as="h2">
						Join The CommunityTo Give Education For Children
					</Heading>
					<Text as="p" className="!text-gray-600">
						Lorem Ipsum is simply dummy text of the printin typesetting dummy text ever when an unknown printer took
						a galley of type and scrambled it to make a type specimen book.{ " " }
					</Text>
					<Img src="/images/img_photo_413x585.png" alt="photo_three" className="w-full object-cover h-[264px]" />
				</div> */}
			<div className="flex flex-col items-start justify-center w-full gap-8 p-8 md:p-[55px] border-gray-300 border border-solid bg-white-A700">
				<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
					{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
						<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
						</svg>
						<span className="sr-only">Info</span>
						<div className="ml-2 mr-2">
							<span className="font-medium">Error!</span> { errorMessage }
						</div>
					</div> }

					<Input required color="gray_100_02" type="email" variant="fill" name="username" label="Email Address" placeholder="Email Address*" className="w-full h-[40px]" autocomplete="off" { ...register('username') }
						error={ errors?.username?.message } />
					<Input required color="gray_100_02" type="password" variant="fill" name="password" label="Password" placeholder="Password*" className="w-full h-[40px]" autocomplete="off" { ...register('password') }
						error={ errors?.password?.message } />

					<div className="flex flex-col md:flex-row gap-5 items-center justify-between w-full">
						<div className="flex items-start">
							<div className="flex items-center h-5">
								<input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
							</div>
							<div className="ml-3 text-sm">
								<label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
							</div>
						</div>
						<Link to="/forgot-password" className="text-sm font-medium text-red-300 hover:underline dark:text-primary-500">Forgot Password?</Link>
					</div>

					<Button className="h-[40px] !text-gray-100_02 uppercase font-medium min-w-[201px]" type='submit'>Login</Button>
					<p className="text-sm font-light text-gray-500 dark:text-gray-400 w-full flex item-center justify-center">
						<span>Don’t have an account yet?</span> <Link to="/register" className="ml-1 font-medium text-red-300 hover:underline dark:text-primary-500">Sign up</Link>
					</p>

				</form>
			</div>
		</div>
	);
};

export { LoginForm };