import React from "react";

const sizes = {
	xs: "text-base font-medium leading-[130%]",
	lg: "text-[30px] font-normal leading-[108.3%]",
	s: "text-sm md:text-base font-normal",
	xl: "text-[40px] font-normal leading-[108.3%]",
	md: "text-base lg:text-md font-normal leading-[130%]",
};

const Text = ({ children, className = "text-white-A700", as, color = "text-white-A700", size = "md", ...restProps }) => {
	const Component = as || "p";

	return (
		<Component className={ `${ color } font-worksans ${ className } ${ sizes[size] }` } { ...restProps }>
			{ children }
		</Component>
	);
};

export { Text };
