import React from "react";
import PropTypes from "prop-types";

const shapes = {
	circle: "rounded-[50%]",
	round: "rounded-[32px]",
};
const variants = {
	outline: {
		white_A700: "border-white-A700 border-2 border-solid text-white-A700",
	},
	fill: {
		white_A700: "bg-white-A700 text-blue_gray-800",
		gray_100_03: "bg-gray-400_01 text-gray-600",
		gray_200_01: "bg-gray-200_01 text-blue_gray-800",
		red_300: "bg-blue text-white-A700",
		blue_gray_800: "bg-blue text-white-A700",
		orange_500: "bg-orange-500 text-white-A700",
	},
};
const sizes = {
	xs: "h-[33px] px-4 text-lg",
	lg: "h-[86px] px-[29px]",
	sm: "h-[56px] px-[15px] text-sm ",
	md: "h-[50px] px-[35px] text-base ",
};

const Button = ({
	children,
	className = "",
	leftIcon,
	rightIcon,
	shape = "round",
	variant = "fill",
	size = "md",
	color = "blue_gray_800",
	...restProps
}) => {
	return (
		<button
			className={ `${ className } flex items-center justify-center text-center cursor-pointer ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[color]) || "" }` }
			{ ...restProps }
		>
			{ !!leftIcon && leftIcon }
			{ children }
			{ !!rightIcon && rightIcon }
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	leftIcon: PropTypes.node,
	rightIcon: PropTypes.node,
	shape: PropTypes.oneOf(["circle", "round"]),
	size: PropTypes.oneOf(["xs", "lg", "sm", "md"]),
	variant: PropTypes.oneOf(["outline", "fill"]),
	color: PropTypes.oneOf(["white_A700", "gray_100_03", "gray_200_01", "red_300", "blue_gray_800", "orange_500"]),
};

export { Button };
