import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Button, Heading, Img, Input, Text } from "components";
import { IconUserCircle, IconUserCheck, IconLockSquare } from '@tabler/icons';
import { EditProfileForm } from "layout/Auth/EditProfileForm";
import { ChangePasswordForm } from "layout/Auth/ChangePasswordForm";
import { KycVerificationForm } from "layout/Auth/KycVerificationForm";

const userProfile = [
	{ value: 'account', label: 'Account', icon: <IconUserCircle /> },
	{ value: 'kyc', label: 'KYC', icon: <IconUserCheck /> },
	{ value: 'security', label: 'Security', icon: <IconLockSquare /> }
];

export default function Register() {
	const [profile, setProfile] = useState('account');
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			<div className={ `w-full mt-16 flex flex-col  min-h-screen-min-topbar ${ profile === 'kyc' && 'mb-16' } ` }>
				<div className="bg-gray-100 py-14 flex justify-center items-center">
					<Heading as="p" size="lg" className="max-w-[480px] w-full px-10">
						User Profile
					</Heading>
				</div>
				<div className="flex justify-center items-center">
					<div className="max-w-[480px] w-full flex gap-10 mt-20">
						<div className="w-[25%] border-r border-gray-500 pr-10 px-10">
							<Heading as="p" size="xs" className=" w-full ">
								Welcome, Mega Musfivawati
							</Heading>
							<div className="mt-10 flex flex-col gap-3">
								{ userProfile.map((data, index) => (
									<div
										key={ index }
										onClick={ () => setProfile(data.value) }
										className={ `flex items-center py-3 text-lg rounded-md gap-3 px-5 ${ data.value === profile ? "!bg-green-200 text-white-A700" : "text-green-200 !bg-gray-100"
											}` }
									>
										{ data.icon }
										<span>{ data.label }</span>
									</div>
								)) }
							</div>
						</div>
						{
							profile === 'account' &&
							<div className="flex flex-col items-start justify-start w-full gap-5">
								<Heading as="p" size="xs" className=" w-full ">
									User Profile Information
								</Heading>
								<EditProfileForm />
							</div>
						}
						{
							profile === 'security' &&
							<div className="flex flex-col items-start justify-start w-full gap-5">
								<Heading as="p" size="xs" className=" w-full ">
									Change Password
								</Heading>
								<ChangePasswordForm />
							</div>
						}
						{
							profile === 'kyc' &&
							<div className="flex flex-col items-start justify-start w-full gap-2">
								<Heading as="p" size="xs" className=" w-full text-center">
									Verify your identity
								</Heading>
								<Text as="p" className="!text-gray-600 w-full text-center mb-5">
									Financial regulations require us to verify your identity. Once completed, you can donate unlimitedly
								</Text>
								<KycVerificationForm />
							</div>
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
