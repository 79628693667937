import React from "react";
import { Text, Img, Heading } from "../../components";

const Hero = ({ imageUrl, title, subtitle }) => {
	return (
		<div className="mt-[60px] md:mt-20 w-full bg-gradient relative">
			<Img
				src={ imageUrl }
				alt="image"
				className="justify-center w-full m-auto object-contain"
			/>
			<div className="bg-black/50 absolute w-full h-full top-0" />
			<div className="flex justify-center  max-w-[480px] w-full md:w-[480px]">
				<div className="flex flex-col items-start justify-center h-full gap-[30px] bottom-0 top-0 m-auto absolute px-10" >
					<Text size="xl" as="p" className="!text-base md:!text-[40px] !font-sueellenfrancisco">
						{ title }
					</Text>
					<Heading size="2xl" as="h1" className="!text-3xl md:!text-[90px] mt-[-25px] !text-white-A700">
						{ subtitle }
					</Heading>
				</div>
			</div>
		</div>
	);
};

export default Hero;