import React, { useState } from "react";
import { Img, Heading, Text, Button, Input } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from "api/Api";
import { Link } from "react-router-dom";

const schema = yup
	.object({
		fullname: yup.string().required().max(50),
		email: yup.string().email().required().max(50),
		date_of_birth: yup.string()
			.when('$user_type', {
				is: 'personal',
				then: yup.string().required()
			}),
		password: yup.string().required().min(6),
		password_confirmation: yup.string().required().min(6).oneOf([yup.ref('password'), null], 'Passwords must match'),
		phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
	})
	.required();

const typeUser = [
	{ value: 'personal', label: 'Personal' },
	{ value: 'corporate', label: 'Corporate' }
];
const RegisterForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [type, setType] = useState('personal');

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema, { context: { user_type: type } }),
		defaultValues: {
			fullname: '',
			email: '',
			date_of_birth: '',
			password: '',
			password_confirmation: '',
			phone: '',
			user_type: ''
		}
	});

	const onSubmit = (data) => {
		data['user_type'] = type;
		loader.showLoader();

		API.post(`register`, data).then(e => {
			loader.hideLoader();
			// Cache.setUser(e.data)
			setSuccessMessage(e.data.send_result);
			setTimeout(() => {
				setSuccessMessage('');
				document.location = '/login';
			}, 3000);
		}).catch(e => {
			loader.hideLoader();

			setErrorMessage('Change a few things up and try submitting again.');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		});
	};


	return (
		<div className="relative mt-10">
			<div className=" py-20 grid grid-cols-1 gap-10  max-w-[480px] w-full md:w-[480px] px-5 min-h-screen-min-topbar">
				<div className="flex flex-col items-start justify-start w-full gap-5">
					<Text size="lg" as="p" className="!text-gray-600 !font-sueellenfrancisco">
						How We Help
					</Text>
					<Heading size="md" as="h2" >
						Join The Community To Give Education For Children
					</Heading>
					<Text size="sm" as="p" className="!text-gray-600 text-justify ">
						Lorem Ipsum is simply dummy text of the printin typesetting dummy text ever when an unknown printer took
						a galley of type and scrambled it to make a type specimen book.{ " " }
					</Text>
					<Img src="/images/img_photo_413x585.png" alt="photo_three" className="w-full object-cover h-[264px]" />
				</div>
				<div className="flex flex-col items-start justify-center w-full gap-5 p-8 border-gray-300 border border-solid bg-white-A700">
					<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
						{ errorMessage !== '' &&
							<div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
								<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
								</svg>
								<span className="sr-only">Info</span>
								<div className="ml-2 mr-2">
									<span className="font-medium">Error!</span>  { errorMessage }
								</div>
							</div>
						}

						{ successMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
							<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
							</svg>
							<span className="sr-only">Info</span>
							<div className="ml-2 mr-2">
								<span className="font-medium">Success!</span>  { successMessage }
							</div>
						</div>
						}
						{
							<div className="flex gap-3">
								{ typeUser.map((data, index) => (
									<Button
										key={ index }
										onClick={ () => setType(data.value) }
										size="sm"
										className={ `h-[40px] gap-2 rounded-[28px] whitespace-nowrap !text-base px-10 ${ data.value === type ? "!bg-green-200" : "text-green-200 !bg-gray-100" }` }
									>
										{ data.label }
									</Button>
								)) }
							</div>
						}
						<Input required color="gray_100_02" variant="fill" label={ type === "personal" ? "Fullname as in ID Proof" : 'Company Name' } name="fullname" placeholder={ type === "personal" ? "Fullname as in ID Proof" : 'Company Name' } className="w-full h-[40px]"  { ...register('fullname') }
							error={ errors?.fullname?.message } />
						{ type === "personal" &&
							<Input
								required
								color="gray_100_02"
								variant="fill"
								label="Date of Birth"
								placeholder="Date of Birth"
								name="date_of_birth"
								type="date"
								className="w-full h-[40px]"
								{ ...register('date_of_birth') }
								error={ errors?.date_of_birth?.message }
							/> }
						<Input
							required
							color="gray_100_02"
							variant="fill"
							name="phone"
							label="Phone Number"
							placeholder="Phone Number*"
							className="w-full h-[40px]"
							{ ...register('phone') }
							error={ errors?.phone?.message }
						/>
						<Input required color="gray_100_02" type="email" variant="fill" name="email" label="Email Address" placeholder="Email Address*" className="w-full h-[40px]" autocomplete="off" { ...register('email') }
							error={ errors?.email?.message } />
						<Input required color="gray_100_02" type="password" variant="fill" name="password" label="Password" placeholder="Password*" className="w-full h-[40px]" autocomplete="off" { ...register('password') }
							error={ errors?.password?.message } />
						<Input required color="gray_100_02" type="password" variant="fill" name="password_confirmation" label="Password Confirmation" placeholder="Password Confirmation*" className="w-full h-[40px]" autocomplete="off" { ...register('password_confirmation') }
							error={ errors?.password_confirmation?.message } />
						<Button className="h-[40px] !text-gray-100_02 uppercase font-medium min-w-[201px]" type='submit'>Register</Button>
						<p className="text-sm font-light text-gray-500 dark:text-gray-400 w-full flex item-center justify-center">
							<span>Already have account?</span> <Link to="/login" className="ml-1 font-medium text-red-300 hover:underline dark:text-primary-500">Log In</Link>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export { RegisterForm };
