import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, Description, Form } from "layout/DetailCharities";
import { useParams } from "react-router-dom";
import API from "api/Api";
import * as loader from "components/Loading/loading";
import Cache from "utils/cache";

export default function DetailCharities() {
	const queryParams = useParams();
	const [charity, setCharity] = useState(null);
	const [institutions, setInstitutionsDetail] = useState(null);
	const [balance, setBalance] = useState(null);
	useEffect(() => {
		charityAPI();
		getBalance();
	}, []);
	const getBalance = () => {
		const token = Cache.getData()?.token;
		if (token == null || token != 'null') {
			return
		}

		loader.showLoader();
		API.getUseToken('aix/balance', token).then(response => {
			setBalance(response.data.result);
			loader.hideLoader();
		}).catch(error => {
			console.log(error);
			loader.hideLoader();
		});
	};
	const charityAPI = () => {
		loader.showLoader();
		API.get(`charities/${ queryParams.id }`).then(e => {
			setCharity(e.data.charity_data);
			API.get(`institutions?institution_id=${ e.data.charity_data.institution_id }`)
				.then(e => {
					loader.hideLoader();
					setInstitutionsDetail(e.data.institutions[0]);
				});
		}).catch(e => {
			loader.hideLoader();
		});
	};
	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700">
			<div className="relative w-full">
				<Header />
			</div>
			{ charity &&
				<div className="flex flex-col max-w-[940px] w-full md:w-[940px] mt-[55px] min-h-screen ">
					<Hero charity={ charity } />
					<div className="h-px bg-gray-100_03 w-full my-5" />
					<Form charity={ charity } institutions={ institutions } balance={ balance ? balance.availableBalance : 0 } fee={ balance ? balance.network.find(network => network.chain.chainId === 3).withdrawalFee || 0 : 0 } />
					<Description charity={ charity } />
				</div>
			}
			<Footer />
		</div>
	);
}
