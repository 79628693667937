import React from "react";
import PropTypes from "prop-types";

export const Loading = React.forwardRef(
  (
    {
      size = 50
    },
    ref
  ) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-90 flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white-A700 text-xl font-semibold">Please Wait...</h2>
      {/* <p className="w-1/3 text-center text-white-A700">This may take a few seconds, please don't close this page.</p> */}
    </div>


    );
  },
);


Loading.propTypes = {
  size: PropTypes.number,
};
