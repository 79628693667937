import React from "react";
import Header from "../../components/Header";
import { Hero } from "layout/Institutions";
import Footer from "../../components/Footer";
import { ForgotPasswordForm } from "layout/Auth/ForgotPasswordForm";
import { ResetPasswordForm } from "layout/Auth/ResetPasswordForm";
import { useSearchParams } from "react-router-dom";

export default function ForgotPassword() {
	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get("token");

	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			{/* <Hero imageUrl="images/img_image_195.png" title="" subtitle="Sharing is Caring" /> */ }
			{ token !== null && token !== "" ? <ResetPasswordForm /> : <ForgotPasswordForm /> }
			<Footer />
		</div>
	);
}
