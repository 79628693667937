import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Hero } from "layout/Institutions";
import Footer from "../../components/Footer";
import { LoginForm } from "layout/Auth/LoginForm";
import { useParams } from "react-router-dom";
import API from "api/Api";
import ModalVerified from "./ModalVerified";

class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			isOpen: false,
		};
	}

	componentDidMount() {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const token = params.get('token');

		if (token !== null && token !== "") {
			this.tokenCheckAPI(token);
		}
	}

	handleCloseModal = () => {
		this.setState({ isOpen: false });
	};

	tokenCheckAPI(token) {
		const data = {
			token: token
		};


		API.post('verify', data).then(e => {
			this.setState({ isOpen: true });
		}).catch(e => {
			console.log(e);
		});
	}

	render() {
		const { isOpen } = this.state;

		return (
			<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
				<div className="relative w-full">
					<Header />
				</div>
				{/* <Hero imageUrl="images/img_image_195.png" title="" subtitle="Sharing is Caring" /> */ }
				<LoginForm />
				<ModalVerified isOpen={ isOpen } onButtonClick={ this.handleCloseModal } />

				<Footer />
			</div>
		);
	}
}
export default Login;
