import React from "react";
import { Img, Heading, Text, Button, Input, TextArea } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
	.object({
		name: yup.string().required().max(50),
		email: yup.string().email().required().max(50),
		phone_number: yup.string().matches(/^[0-9]+$/, "Must be only digits").required().min(10).max(15),
		message: yup.string().required(),
	})
	.required();


const Form = () => {
	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
	});
	const onSubmit = (data) => console.log(data);

	return (
		<div className="py-20 grid grid-cols-1 md:grid-cols-2 gap-10  max-w-[940px] w-full md:w-[940px]  px-10 mt-10 min-h-screen-min-topbar">
			<div className="flex flex-col items-start justify-start w-full gap-5">
				<Text size="lg" as="p" className="!text-blue !font-sueellenfrancisco">
					Contact
				</Text>
				<Heading size="s" as="h5">
					Get in Touch With Us
				</Heading>
				<Text size="s" as="p" className="!text-gray-600">
					We'd love to hear from you! Whether you have a question, feedback, or need assistance, feel free to reach out to us through any of the following channels.{ " " }
				</Text>
				<div className="flex flex-row justify-start items-center gap-5">
					<div className="flex flex-col items-center justify-start h-[89px] w-[89px] p-6 bg-gray-100_03  rounded-2xl">
						<div className="flex flex-row justify-center mt-0.5 mb-2 p-0.5 border-blue border-[3px] border-solid">
							<Img src="/images/img_vector_1_red_300.svg" alt="vectorone_three" className="h-[14px] mb-2" />
						</div>
					</div>
					<div className="flex flex-col items-start justify-center gap-px">
						<Heading size="xs" as="h5">
							Message
						</Heading>
						<Text size="s" as="p" className="!text-gray-600">
							customerservice@aix.asia
						</Text>
					</div>
				</div>
				<div className="flex flex-row justify-start items-center w-full gap-5">
					<div className="flex flex-col items-start justify-center h-[89px] w-[89px] p-[25px] bg-gray-100_03 rounded-2xl">
						<Img src="/images/img_vector.svg" alt="vector_one" className="h-[35px] w-[35px]" />
					</div>
					<div className="flex flex-col items-start justify-start w-[67%] gap-px">
						<Heading size="xs" as="h5">
							Contact Us
						</Heading>
						<Text size="s" as="p" className="!text-gray-600">
							+60 19 653 0993
						</Text>
					</div>
				</div>
			</div>
			<form onSubmit={ handleSubmit(onSubmit) }>
				<div className="flex flex-col items-center justify-center w-full gap-5 md:gap-[50px] p-8 md:p-[55px] border-gray-300 border border-solid bg-white-A700 rounded-2xl">
					<div className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
						<Input color="gray_100_02" variant="fill" name="name" placeholder="Enter Your Name*" className="w-full h-[40px]" { ...register('name') }
							error={ errors?.name?.message }
						/>
						<Input
							color="gray_100_02"
							variant="fill"
							name="phone"
							placeholder="Phone Number*"
							className="w-full h-[40px]"
							error={ errors?.phone_number?.message }
							{ ...register('phone_number') }
						/>
						<Input type="email" color="gray_100_02" variant="fill" name="email" placeholder="Email Address*" className="w-full h-[40px]" { ...register('email') }
							error={ errors?.email?.message }
						/>
						<TextArea
							color="gray_100_02"
							size="sm"
							variant="tarFillGray10002"
							name="message"
							placeholder="Your Message*"
							className="w-full text-gray-500 h-[100px]"
							{ ...register('message') }
							error={ errors?.message?.message }
						/>
					</div>
					<Button className="mb-[3px] !text-gray-100_02 uppercase font-medium min-w-[201px]" type="submit"> Submit</Button>
				</div>
			</form>
		</div>
	);
};

export default Form;
