import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { RegisterForm } from "layout/Auth/RegisterForm";
import { Button, Heading, Img, Input, Text } from "components";
import Cache from "utils/cache";
import { KycVerificationForm } from "layout/Auth/KycVerificationForm";

export default function Register() {
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			<div className=" py-20 grid grid-cols-1 md:grid-cols-2 gap-10  max-w-[480px] w-full md:w-[480px] px-10 mt-10 min-h-screen-min-topbar">
				<div className="flex flex-col items-start justify-start w-full gap-5">
					<Text size="lg" as="p" className="!text-red-300 !font-sueellenfrancisco">
						KYC Verification
					</Text>

					<div className="flex flex-row justify-start items-center gap-5 w-full">
						<KycVerificationForm />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
