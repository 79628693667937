import React, { useEffect, useState } from "react";
import { Heading, Input, SelectBox, Text } from "components";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { IconSquare, IconSquareCheck } from "@tabler/icons";
import BeneficiaryTable from "./BeneficiaryTable";

const beneficiarySchema = yup.object().shape({
	fullname: yup.string().required('Full name is required'),
	idCard: yup.string().required('ID Card/Passport No. is required'),
	dob: yup.string().required('Date of Birth is required'),
	nationality: yup.string().required('Nationality is required'),
	percentage: yup.string().required('Percentage share is required'),
	idProof: yup
		.mixed()
		.required('ID proof is required')
		.test('fileSize', 'File size exceeds 5 MB', value => {
			return value && value[0] && value[0].size <= 5 * 1024 * 1024;
		})
		.test('fileType', 'Unsupported file format', value => {
			return value && value[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(value[0].type);
		}),
});
const schema = yup.object({
	owner: yup.array().of(beneficiarySchema).required('At least one beneficiary owner is required'),
}).required();

const BeneficiaryOwnerDetails = (setErrorMessage) => {
	const { register, formState: { errors }, setValue, getValues, reset } = useFormContext();
	const [checked, setChecked] = useState(0);
	return (
		<div className="mt-[3px] w-full flex flex-col gap-5">
			<Heading as="p" size="xs" className=" w-full">
				Beneficiary Owner Details
			</Heading>
			<div className="flex gap-2 items-center h-full w-[90%]">
				<div>
					{ checked === 1 ? (
						<IconSquareCheck color="#717171" width={ 30 } height={ 30 } onClick={ () => setChecked(0) } />
					) : (
						<IconSquare color="#717171" width={ 30 } height={ 30 } onClick={ () => setChecked(1) } />
					) }
				</div>
				<Text className="!text-gray-600 !text-base">
					I/We declare that the beneficial owners, i.e individual(s) who ultimately own(s) or effectively control(s)
					the company (regardless of shareholding). and the percentage shares held by the beneficial owners of the
					company are as follows
				</Text>
			</div>
			{ checked === 1 && (
				<BeneficiaryTable checked={ checked } register={ register } errors={ errors } setValue={ setValue } getValues={ getValues } reset={ reset } setErrorMessage={ setErrorMessage } />
			) }
			<div className="flex gap-2 items-center h-full w-[90%]">
				<div>
					{ checked === 2 ? (
						<IconSquareCheck color="#717171" width={ 30 } height={ 30 } onClick={ () => setChecked(0) } />
					) : (
						<IconSquare color="#717171" width={ 30 } height={ 30 } onClick={ () => setChecked(2) } />
					) }
				</div>
				<Text className="!text-gray-600 !text-base">
					I/We declare the company is ultimately owned by the following entity
				</Text>
			</div>
			{ checked === 2 && (
				<BeneficiaryTable checked={ checked } register={ register } errors={ errors } setValue={ setValue } getValues={ getValues } reset={ reset } setErrorMessage={ setErrorMessage } />
			) }
		</div>
	);
};

export { BeneficiaryOwnerDetails, schema as BeneficiaryOwnerDetailsSchema };