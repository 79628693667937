import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Institutions } from "layout/Institutions";
import API from 'api/Api';
import 'react-loading-skeleton/dist/skeleton.css';
import { Heading, Img } from "components";
import { useParams } from "react-router-dom";
import * as loader from "components/Loading/loading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'utils/quill-editor/Quill.css';

export default function DetailFoundationsPage() {
	const queryParams = useParams();
	const [foundations, setFoundations] = useState(null);
	const [foundationsDetail, setFoundationsDetail] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		getAPIInstitutions();
	}, [page]);

	useEffect(() => {
		getFoundationsDetails();
	}, []);

	const getAPIInstitutions = () => {
		loader.showLoader();
		API.get(`institutions?limit=0&page=0&filter=&foundation_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setFoundations(e.data.institutions);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};
	const getFoundationsDetails = () => {
		loader.showLoader();
		API.get(`foundations?foundation_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setFoundationsDetail(e.data.foundations);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};

	const onPrevClick = () => {
		if (page > 1) {
			setPage(prevPage => prevPage - 1);
		}
	};

	const onNextClick = () => {
		setPage(prevPage => prevPage + 1);
	};
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			{ foundationsDetail && (
				<div className="min-h-screen-min-topbar mt-16 max-w-[940px] w-full md:w-[940px] ">
					<Img src={ foundationsDetail[0].image_url } alt="foundations image" className="w-full object-cover max-h-[300px] " />
					<Heading as="p" size="lg" className="w-full mt-10 px-10">
						{ foundationsDetail[0].name }
					</Heading>
					<ReactQuill
						className="w-full !text-blue_gray-700 px-10 mt-5"
						value={ foundationsDetail[0].description } readOnly={ true }
						theme="bubble"
						modules={ { toolbar: false } } />
					{/* <Heading as="p" className="w-full !text-base !font-normal !text-blue_gray-700 px-10 mt-5" dangerouslySetInnerHTML={ { __html: foundationsDetail[0].description } } /> */ }
					{ foundations && foundations.length > 0 && (
						<Institutions
							institutions={ foundations }
							onPrevClick={ onPrevClick }
							onNextClick={ onNextClick }
						/>
					) }
				</div>
			) }
			<Footer />
		</div>
	);
}
