import React from "react";
import { Button, Heading, Text, Modal } from "../../components";

export default function ModalVerified({ isOpen, onButtonClick }) {

	return (
		<Modal
			isOpen={ isOpen }
			size="sm"
			className="flex flex-col py-14 px-20 gap-10">
			<div className="flex flex-col justify-center items-center w-full">
				<div className="flex justify-center w-full gap-2">
					<Heading size="s" as="h2" className="!font-bold">
						Congratulations!
					</Heading>			
				
				</div>			
				<Text className="!text-gray-600 !text-base mt-3">
						Your Account is successfully registered, please login to continue.
					</Text>		
			</div>

			<div onClick={ onButtonClick } className="flex justify-center w-full">
				<Button size="sm" className="uppercase !text-base px-16">Close</Button>
			</div>
		</Modal>
	);
}