import React, { useState } from "react";
import { Img, Heading, Text, Button, Input, TextArea } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from 'api/Api';
import Cache from 'utils/cache';


const schema = yup
	.object({
		oldpassword: yup.string().required().min(6),
		newpassword: yup.string().required().min(6),
		password_confirmation: yup.string().required().min(6).oneOf([yup.ref('newpassword'), null], 'Passwords must match')
	})
	.required();


const ChangePasswordForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
	});
	const onSubmit = (data) => {
		loader.showLoader();
		data['username'] = Cache.getUser()?.username;

		API.post(`changepassword`, data).then(e => {
			loader.hideLoader();
			setSuccessMessage('Please re-login to update the changes');
			setTimeout(() => {
				setSuccessMessage('');
				Cache.clearLogin();
				document.location = '/login';
			}, 3000);
		}).catch(e => {
			loader.hideLoader();

			setErrorMessage('Change a few things up and try submitting again.');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		});
	};


	return (
		<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
			{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
				<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
				</svg>
				<span className="sr-only">Info</span>
				<div className="ml-2 mr-2">
					<span className="font-medium">Error!</span> { errorMessage }
				</div>
			</div> }
			{ successMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
				<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
				</svg>
				<span className="sr-only">Info</span>
				<div className="ml-2 mr-2">
					<span className="font-medium">Success!</span>  { successMessage }
				</div>
			</div>
			}
			<Input color="gray_100_02" type="password" variant="fill" name="oldpassword" label="Old Password" placeholder="Old Password*" className="w-full" autocomplete="off" { ...register('oldpassword') }
				error={ errors?.oldpassword?.message } />
			<Input color="gray_100_02" type="password" variant="fill" name="newpassword" label="New Password" placeholder="New Password*" className="w-full" autocomplete="off" { ...register('newpassword') }
				error={ errors?.newpassword?.message } />
			<Input color="gray_100_02" type="password" variant="fill" name="password_confirmation" label="Password Confirmation" placeholder="Password Confirmation*" className="w-full" autocomplete="off" { ...register('password_confirmation') }
				error={ errors?.password_confirmation?.message } />


			<Button className=" !text-gray-100_02 uppercase font-medium min-w-[201px]" type='submit'>Submit</Button>

		</form>
	);
};

export { ChangePasswordForm };