import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, Institutions } from "layout/Institutions";
import API from 'api/Api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Heading } from "components";


class InstitutionsPage extends React.Component {

	constructor() {
		super();

		this.state = {
			institutions: [],
			limit: 10,
			page: 1,
			total_page: 1,
			loading: true,
		};
	}

	componentDidMount() {
		this.getAPIInstitutions();
	}

	getAPIInstitutions() {
		const { limit, page } = this.state;
		this.setState({ loading: true });

		API.get(`institutions?limit=${ limit }&page=${ page }`).then(e => {
			this.setState({ institutions: e.data.charities, loading: false, total_page: e.data.paging.total_page }, () => {
				console.log(e);
			});
		}).catch(e => {
			this.setState({ loading: false });
			console.log(e);
		});
	}

	onPrevClick = () => {
		this.setState({ page: this.state.page - 1 }, () => {
			this.getAPIInstitutions();
		});
	};

	onNextClick = () => {
		this.setState({ page: this.state.page + 1 }, () => {
			this.getAPIInstitutions();
		});
	};

	render() {
		const { institutions, loading, page, total_page } = this.state;


		return (
			<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
				<div className="relative w-full">
					<Header />
				</div>
				<div className="min-h-screen-min-topbar mt-10">
					{/* <Hero imageUrl="images/img_group_kids_frie.png" title="Donation" subtitle="Our Partners" /> */ }
					{
						!loading && <Heading as="p" size="lg" className="mt-20">
							Institutions
						</Heading> }
					{ !loading && this.state.institutions && this.state.institutions.length > 0 && <Institutions institutions={ institutions } onPrevClick={ this.onPrevClick } onNextClick={ this.onNextClick } /> }
					{ loading && <div className="w-full" >
						<div className="w-full py-5 flex justify-center">
							<div className="justify-center gap-x-16 grid-cols-2 grid  max-w-[480px] w-full md:w-[480px] px-10">
								<div>
									<Skeleton height={ 100 } />
									<Skeleton count={ 5 } />
								</div>
								<div>
									<Skeleton height={ 100 } />
									<Skeleton count={ 5 } />
								</div>
							</div>
						</div>
						<div className="w-full py-5 flex justify-center ">
							<div className="justify-center  gap-x-16 grid-cols-2 grid max-w-[480px] w-full md:w-[480px] px-10">
								<div>
									<Skeleton height={ 100 } />
									<Skeleton count={ 5 } />
								</div>
								<div>
									<Skeleton height={ 100 } />
									<Skeleton count={ 5 } />
								</div>
							</div>
						</div>
					</div>
					}
				</div>

				<Footer />
			</div>
		);
	}
}

export default InstitutionsPage;
