import React from "react";
import { Img, Heading, Text } from "../../components";

const AboutUs = () => {
	return (
		<div className="py-10  max-w-[940px] lg:w-[940px] px-10 grid lg:grid-cols-2 grid-cols-1 bg-white-A700 w-full gap-10">
			<div className="flex flex-col justify-start lg:items-start items-center gap-[35px]">
				<Heading size="md" as="h2">
					Introduction
				</Heading>
				<Text size="s" as="p" className="!text-gray-600 text-justify">
					SedeqahTech represents a groundbreaking platform designed to facilitate Sedeqah contribution seamlessly, integrating both fiat and cryptocurrency options. With a mission to enhance accessibility and transparency, SedeqahTech provides individuals worldwide with a user-friendly gateway to fulfill their religious obligations while embracing the innovation of digital currencies.
				</Text>
			</div>
			<div className="flex flex-col items-start justify-start gap-[33px] relative">
				<Img src="/images/img_introduction.png" alt="photo_three" className="w-full object-cover" />
			</div>
		</div>
	);
};

export default AboutUs;