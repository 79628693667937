import React from "react";
import PropTypes from "prop-types";
import { Text } from "components";

const shapes = {
	square: "rounded-[0px]",
};
const variants = {
	tarOutlineWhiteA700: "border-white-A700 border border-solid",
	tarFillGray10002: "bg-gray-100_02",
};
const sizes = {
	xs: "h-[156px] p-[22px] text-base md:text-lg",
	sm: "h-[190px] p-[22px] text-base md:text-lg",
};

const TextArea = React.forwardRef(
	(
		{
			className = "",
			name = "",
			label = "",
			placeholder = "",
			shape = "square",
			size = "xs",
			variant = "tarOutlineWhiteA700",
			onChange,
			error = "",
			required = false,
			...restProps
		},
		ref,
	) => {
		const handleChange = (e) => {
			if (onChange && e !== undefined) onChange(e);
		};

		return (
			<>
				<div className="flex flex-col items-start justify-start w-full gap-[7px]">
					<Text as="p" className="!text-blue_gray-700">
						{ !!label && label }{ required && <span className="text-red-500">*</span> }
					</Text>
					<textarea
						ref={ ref }
						className={ `${ className } ${ shapes[shape] || "" } ${ sizes[size] || "" } ${ variants[variant] || "" }` }
						name={ name }
						onChange={ handleChange }
						placeholder={ placeholder }
						{ ...restProps }
					/>
					{ error !== "" && <p className="text-red-500 text-xs italic w-full">{ error }</p> }
				</div>

			</>
		);
	},
);

TextArea.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	shape: PropTypes.oneOf(["square"]),
	size: PropTypes.oneOf(["xs", "sm"]),
	error: PropTypes.string,
	required: PropTypes.bool,
	variant: PropTypes.oneOf(["tarOutlineWhiteA700", "tarFillGray10002"]),
};

export { TextArea };
