import React, { useState } from "react";
import { Img, Heading, Text, Button, Input, TextArea } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from 'api/Api';
import Cache from 'utils/cache';
import { PersonalInfo, PersonalInfoSchema } from "./Kyc/PersonalInfo";
import { FinanceInfo, FinanceInfoSchema } from "./Kyc/FinanceInfo";
import { IconCheck } from "@tabler/icons";
import { CorporateInfo, CorporateInfoSchema } from "./Kyc/CorporateInfo";
import { CorporateFinanceInfo, CorporateFinanceInfoSchema } from "./Kyc/CorporateFinancing";
import { DocumentUpload, DocumentUploadSchema } from "./Kyc/DocumentUpload";
import { BeneficiaryOwnerDetails, BeneficiaryOwnerDetailsSchema } from "./Kyc/BeneficiaryOwnerDetails";

const KycVerificationForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [currentStep, setCurrentStep] = useState(1);
	const [sameAddress, setSameAddress] = useState(true);
	const role = Cache.getUser().user_type;
	console.log("role", role, Cache.getUser());
	const nextStep = () => {
		setCurrentStep(prev => prev + 1);
	};

	const prevStep = () => {
		setCurrentStep(prev => prev - 1);
	};

	const getSchema = () => {
		if (role === 'personal') {
			return yup.object().shape({
				...PersonalInfoSchema.fields,
				...FinanceInfoSchema.fields,
				kyc_corresponding_address: !sameAddress
					? yup.string().required("Corresponding Address is required")
					: yup.string(),
				kyc_corresponding_city: !sameAddress
					? yup.string().required("Corresponding City is required")
					: yup.string(),
				kyc_corresponding_postal_code: !sameAddress
					? yup.string().required("Corresponding Postal code is required")
					: yup.string(),
				kyc_corresponding_country: !sameAddress
					? yup.string().required("Corresponding Country is required")
					: yup.string(),
			});
		} else {
			return yup.object().shape({
				...CorporateInfoSchema.fields,
				...CorporateFinanceInfoSchema.fields,
				...BeneficiaryOwnerDetailsSchema.fields,
				...DocumentUploadSchema.fields,
				kyc_business_address: !sameAddress
					? yup.string().required("Corresponding Address is required")
					: yup.string(),
				kyc_business_city: !sameAddress
					? yup.string().required("Corresponding City is required")
					: yup.string(),
				kyc_business_postal_code: !sameAddress
					? yup.string().required("Corresponding Postal code is required")
					: yup.string(),
				kyc_business_country: !sameAddress
					? yup.string().required("Corresponding Country is required")
					: yup.string(),
			});
		}
	};
	const methods = useForm({
		resolver: yupResolver(getSchema()),
		mode: "onChange"
	});

	const onSubmit = (data) => {
		console.log("Submitted data", data);
	};
	// const onSubmit = (data) => {
	// 	if (getValues('files').length === 0) {
	// 		setErrorMessage('Please upload file before submitting');
	// 		setTimeout(() => {
	// 			setErrorMessage('');
	// 		}, 3000);
	// 	} else {
	// 		loader.showLoader();
	// 		let fileUpload1 = new FormData();
	// 		fileUpload1.append('files', getValues('files')[0]);

	// 		API.postMultipart(`file-upload`, fileUpload1).then(e => {
	// 			loader.hideLoader();
	// 			verifyKYCAPI(data, e.data.image_url);
	// 		}).catch(e => {
	// 			loader.hideLoader();

	// 			setErrorMessage('Change a few things up and try submitting again.');
	// 			setTimeout(() => {
	// 				setErrorMessage('');
	// 			}, 3000);
	// 		});
	// 	}
	// };
	return (
		<FormProvider { ...methods }>
			<form onSubmit={ methods.handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-5">
				<div className="w-full lg:w-2/4 lg:max-w-[480px]">
					<ul className="flex justify-between text-center items-center mx-auto pl-0">
						{ (role === 'personal' ? [1, 2] : [1, 2, 3, 4]).map(step => (
							<React.Fragment key={ step }>
								<li className={ `flex-1 text-center ${ currentStep === step ? 'text-green-700' : 'text-gray-700' }` }>
									<div className={ `mx-2 w-10 h-10 rounded-full flex items-center justify-center ${ currentStep === step ? 'bg-blue_gray-700' : 'bg-blue_gray-50' }` }>
										{ currentStep === step ? <IconCheck color="#FFFFFF" /> : step }
									</div>
								</li>
								{ step < (role === 'personal' ? 2 : 4) && <div className="h-px bg-blue_gray-700 w-full"></div> }
							</React.Fragment>
						)) }
					</ul>
				</div>
				{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
					<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
					</svg>
					<span className="sr-only">Info</span>
					<div className="ml-2 mr-2">
						<span className="font-medium">Error!</span> { errorMessage }
					</div>
				</div> }
				{ role === 'personal' && currentStep === 1 && <PersonalInfo setSameAddress={ setSameAddress } sameAddress={ sameAddress } /> }
				{ role === 'personal' && currentStep === 2 && <FinanceInfo /> }
				{ role !== 'personal' && currentStep === 1 && <CorporateInfo setSameAddress={ setSameAddress } sameAddress={ sameAddress } /> }
				{ role !== 'personal' && currentStep === 2 && <CorporateFinanceInfo /> }
				{ role !== 'personal' && currentStep === 3 && <BeneficiaryOwnerDetails setErrorMessage={ setErrorMessage } /> }
				{ role !== 'personal' && currentStep === 4 && <DocumentUpload /> }
				<div className="flex justify-between w-full px-5 mt-5">
					{ currentStep > 1 && <Button color="gray_100_03" className="!h-[40px] font-medium min-w-[201px]" type="button" onClick={ prevStep }>Back</Button> }
					{ currentStep < (role === 'personal' ? 2 : 4) && <Button className="!h-[40px] !text-gray-100_02 font-medium min-w-[201px]" type="button" onClick={ nextStep }>Next</Button> }
					{ currentStep === (role === 'personal' ? 2 : 4) && <Button className="!h-[40px] !text-gray-100_02 font-medium min-w-[201px]" type="submit">Submit</Button> }
				</div>
			</form>
		</FormProvider>

	);
};

export { KycVerificationForm };