import React from "react";
import { Heading, Input, SelectBox } from "components";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

const schema = yup
	.object({
		kyc_nature_of_business: yup.string().required(),
		kyc_source_of_fund: yup.string().required(),
		kyc_net_worth: yup.string().required(),
		kyc_declarations: yup.string().required(),
		kyc_politically: yup.string().required(),
		kyc_tax_country: yup.string().required(),
		kyc_tax_number: yup.string().required(),
	})
	.required();

const source_of_fund = [{ value: "salary", label: "Salary & Saving" }, { value: "investment", label: "Investment" }, { value: "business", label: "Business" },];
const annual_income = [{ value: "1", label: "USD <49,999.99" }, { value: "2", label: "USD 50,000.00 – 99,999.99" }, { value: "3", label: "- USD 100,000.00 – 499,999.99" }, { value: "4", label: "USD 500,000.00 – 999,999.99" }, { value: "5", label: "USD >1,000,000.00" }];
const yesOrNo = [{ value: "1", label: "Yes" }, { value: "2", label: "No" }];

const CorporateFinanceInfo = () => {
	const { register, formState: { errors }, setValue, getValues } = useFormContext();

	return (
		<div className="mt-[3px] w-full flex flex-col gap-5">
			<Heading as="p" size="xs" className=" w-full mt-5">
				Financial Background
			</Heading>
			<div className="grid grid-cols-2 w-full gap-5">
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_nature_of_business" label="Nature of Business" placeholder="Nature of Business*" className="w-full" autocomplete="off" { ...register('kyc_nature_of_business') }
					error={ errors?.kyc_nature_of_business?.message } />
				<SelectBox
					name="kyc_source_of_fund"
					size="md"
					label="Source Of Fund"
					placeholder="Source Of Fund"
					color="gray_100_03"
					options={ source_of_fund }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_source_of_fund', e.value);
					}
					}
					value={ getValues('kyc_source_of_fund') }
					error={ errors?.kyc_source_of_fund?.message }
					{ ...register('kyc_source_of_fund') }
				/>
				<SelectBox
					name="kyc_net_worth"
					size="md"
					label="Net Worth as on Today"
					placeholder="Net Worth as on Today"
					color="gray_100_03"
					options={ annual_income }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_net_worth', e.value);
					}
					}
					value={ getValues('kyc_net_worth') }
					error={ errors?.kyc_net_worth?.message }
					{ ...register('kyc_net_worth') }
				/>
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_tax_country" label="Tax Country" placeholder="Tax Country*" className="w-full" autocomplete="off" { ...register('kyc_tax_country') }
					error={ errors?.kyc_tax_country?.message } />
				<Input required color="gray_100_02" type="text" variant="fill" name="kyc_tax_number" label="Tax Number" placeholder="Tax Number*" className="w-full" autocomplete="off" { ...register('kyc_tax_number') }
					error={ errors?.kyc_tax_number?.message } />
			</div>

			<Heading as="p" size="xs" className=" w-full mt-5">
				Self-Declarations
			</Heading>
			<div className="flex flex-col w-full gap-5">
				<SelectBox
					name="kyc_declarations"
					size="md"
					label="Declaration Of “Non-Us Person or Us Person”"
					placeholder="Yes / No"
					color="gray_100_03"
					options={ yesOrNo }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_declarations', e.value);
					}
					}
					value={ getValues('kyc_declarations') }
					error={ errors?.kyc_declarations?.message }
					{ ...register('kyc_declarations') }
				/>
				<SelectBox
					name="kyc_politically"
					size="md"
					label="Are you a Politically Exposed Person (PEP) or Related to a PEP ?"
					placeholder="Yes / No"
					color="gray_100_03"
					options={ yesOrNo }
					className="w-full !h-[51px] !rounded-none"
					handleChange={ (e) => {
						setValue('kyc_politically', e.value);
					}
					}
					value={ getValues('kyc_politically') }
					error={ errors?.kyc_politically?.message }
					{ ...register('kyc_politically') }
				/>
			</div>
		</div>
	);
};

export { CorporateFinanceInfo, schema as CorporateFinanceInfoSchema };