import React, { useState } from 'react';
import { Button, Input } from 'components';

const BeneficiaryTable = ({ register, errors, setValue, getValues, reset, setErrorMessage, checked }) => {
	const [beneficiaries, setBeneficiaries] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);

	const handleField = () => {
		if (getValues('idProof').length === 0) {
			setErrorMessage('Please upload file before submitting');
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		} else {
			const newBeneficiary = getValues();
			const updatedBeneficiaries = [...beneficiaries];

			if (editingIndex !== null) {
				updatedBeneficiaries[editingIndex] = newBeneficiary;
				setEditingIndex(null);
			} else {
				updatedBeneficiaries.push(newBeneficiary);
			}

			setBeneficiaries(updatedBeneficiaries);
			reset();
		}
	};

	const handleEdit = (index) => {
		const beneficiary = beneficiaries[index];
		setEditingIndex(index);
		for (const key in beneficiary) {
			setValue(key, beneficiary[key]);
		}
	};

	const handleFileChange = (e) => {
		console.log("e", e);
	};

	const config = {
		1: {
			fullname: 'Full Name as in ID Proof',
			idCard: 'Identification Card/Passport No.',
			dob: 'Date Of Birth',
			nationality: 'Nationality',
			percentage: 'Percentage Share (%)',
			idProof: 'Beneficial Owner IDs',
		},
		2: {
			fullname: 'Company Name',
			idCard: 'Company Registration',
			dob: 'Date of Registration',
			nationality: 'Country of Incorporation',
			percentage: 'Percentage Share (%)',
			idProof: 'Certificate of Incorporation',
		}
	};

	const labels = config[checked];
	console.log("beneficiary", beneficiaries);
	return (
		<div className="py-3">
			<div className="px-10 grid grid-cols-2 gap-5 mb-5">
				<Input
					required
					color="gray_100_02"
					type="text"
					variant="fill"
					name="fullname"
					label={ labels.fullname }
					placeholder={ labels.fullname }
					className="w-full"
					autoComplete="off"
					{ ...register('fullname') }
					error={ errors?.fullname?.message }
				/>
				<Input
					required
					color="gray_100_02"
					type="text"
					variant="fill"
					name="idCard"
					label={ labels.idCard }
					placeholder={ labels.idCard }
					className="w-full"
					autoComplete="off"
					{ ...register('idCard') }
					error={ errors?.idCard?.message }
				/>
				<Input
					required
					color="gray_100_02"
					variant="fill"
					label={ labels.dob }
					placeholder={ labels.dob }
					name="dob"
					type="date"
					className="w-full"
					{ ...register('dob') }
					error={ errors?.dob?.message }
				/>
				<Input
					required
					color="gray_100_02"
					type="text"
					variant="fill"
					name="nationality"
					label={ labels.nationality }
					placeholder={ labels.nationality }
					className="w-full"
					autoComplete="off"
					{ ...register('nationality') }
					error={ errors?.nationality?.message }
				/>
				<Input
					required
					color="gray_100_02"
					type="text"
					variant="fill"
					name="percentage"
					label={ labels.percentage }
					placeholder={ labels.percentage }
					className="w-full"
					autoComplete="off"
					{ ...register('percentage') }
					error={ errors?.percentage?.message }
				/>
				<Input
					required
					color="gray_100_02"
					type="file"
					variant="fill"
					name="idProof"
					label={ 'Upload' + ' ' + labels.idProof }
					placeholder={ labels.idProof }
					className="w-full"
					autoComplete="off"
					error={ errors?.idProof?.message }
					{ ...register('idProof') }
					accept=".png, .jpg, .jpeg, .pdf"
					onChange={ (e) => handleFileChange(e.target.files[0]) }
				/>
				{
					checked === 2 &&
					<Input
						required
						color="gray_100_02"
						type="file"
						variant="fill"
						name="licenseProof"
						label='Upload Certificate of License'
						placeholder='Upload Certificate of License'
						className="w-full"
						autoComplete="off"
						error={ errors?.licenseProof?.message }
						{ ...register('licenseProof') }
						accept=".png, .jpg, .jpeg, .pdf"
						onChange={ (e) => handleFileChange(e.target.files[0]) }
					/>
				}
			</div>
			<div className="px-10 flex justify-between items-center mb-10">
				<Button
					className={ `p-2 text-white rounded !h-[40px] !text-gray-100_02 font-medium min-w-[201px] ${ getValues('fullname') &&
						getValues('idCard') &&
						getValues('nationality') &&
						getValues('percentage') &&
						getValues('idProof')
						? 'cursor-pointer'
						: 'bg-gray-400 cursor-not-allowed'
						}` }
					type="button"
					onClick={ handleField }
				>
					{ editingIndex !== null && checked === 1 ? 'Update Beneficial Owner' : editingIndex === null && checked === 1 ? 'Add Beneficial Owner' : editingIndex !== null && checked === 2 ? 'Update Beneficial Company' : 'Add Beneficial Company' }
				</Button>
			</div>
			<table className="min-w-full bg-white">
				<thead>
					<tr className="w-full border-b-2 border-black">
						<th className="py-2 px-4 text-left">{ labels.fullname }</th>
						<th className="py-2 px-4 text-left">{ labels.idCard }</th>
						<th className="py-2 px-4 text-left">{ labels.dob }</th>
						<th className="py-2 px-4 text-left">{ labels.nationality }</th>
						<th className="py-2 px-4 text-left">{ labels.percentage }</th>
						<th className="py-2 px-4 text-left">{ labels.idProof }</th>
						{
							checked === 2 &&
							<th className="py-2 px-4 text-left">Certificate of License</th>
						}
						<th className="py-2 px-4 text-left">Action</th>
					</tr>
				</thead>
				<tbody>
					{ beneficiaries.map((beneficiary, index) => (
						<tr key={ index } className="border-b">
							<td className="py-2 px-4">{ beneficiary.fullname }</td>
							<td className="py-2 px-4">{ beneficiary.idCard }</td>
							<td className="py-2 px-4">{ beneficiary.dob }</td>
							<td className="py-2 px-4">{ beneficiary.nationality }</td>
							<td className="py-2 px-4">{ beneficiary.percentage }</td>
							<td className="py-2 px-4">{ beneficiary.idProof }</td>
							{
								checked === 2 &&
								<th className="py-2 px-4 text-left">{ beneficiary.licenseProof }</th>
							}
							<td className="py-2 px-4 text-center cursor-pointer" onClick={ () => handleEdit(index) }>✏️</td>
						</tr>
					)) }
				</tbody>
			</table>
		</div>
	);
};

export default BeneficiaryTable;
