import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { Img } from "../components";
const NotFound = () => {
	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700 overflow-hidden">
			<div className="relative w-full">
				<Header showBackButton={ false } />
			</div>
			<div className="max-w-[940px] w-full md:w-[940px] px-10 h-screen gap-3 flex flex-col justify-center items-center ">
				<Img src='/images/img_global_impact.png' alt='404' className="w-[60%] md:w-[300px]" />
				<h1 className="text-4xl font-bold mb-4">Opps!!!</h1>
				<h4 className="text-xl mb-4 text-center">
					The page you are looking for could not be found.
				</h4>
				<Link
					to="/"
					className="inline-block px-6 py-2 text-white-A700 bg-blue_gray-800_01 rounded-md mt-4"
				>
					Go Back to Home
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
